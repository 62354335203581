<template lang="pug">
  v-col
    v-slide-group(:id="uid" show-arrows)
      v-slide-item(v-for="(image, i) in photos" :key="image.id")
        div(class="image-coord-container mr-2")
          v-btn(fab rounded x-small :disabled="image.loading" @click.stop="removePhoto(image)")
            v-icon mdi-delete
          v-btn( v-if="image.alreadyLoaded" fab rounded x-small :disabled="image.loading" @click.stop="removePhoto(image)" )
            v-icon( color="red" ) mdi-alert-circle
          div.text-img
            img(class="image-coord" :src="getResource(image)" @click="openCarousel(photos, i)")
    v-dialog(v-model="dialogPhoto" width="unset" content-class="image-coordinator-window")
      v-carousel(hide-delimiters v-model="current_carousel" height="auto")
        v-carousel-item(class="text-center" v-for="(item, i) in carousel" :key="i")
          img(:src="getResource(item)")
</template>


<script>
    import { config } from '@/_helpers'
    import { fileService } from '@/_services'

    export default {
        props: ['photos', 'item'],
        data: () => ({
            carousel: [],
            current_carousel: 1,
            dialogPhoto: false,
        }),
        computed: {
          uid() {
            return 'slider_' + this.id
          }
        },
        mounted() {
          let el = document.getElementById(this.uid)
          let list = el.getElementsByClassName('v-slide-group__prev')
          list[0].classList.add('v-slide-view')
          list = el.getElementsByClassName('v-slide-group__next')
          list[0].classList.add('v-slide-view')
          list[0].classList.remove('v-slide-group__next--disabled')
        },
        methods: {
            getResource(image) {
              return config.tmpimage(image.photo)
            },
            openCarousel(photos, idx) {
              this.current_carousel = idx
              this.carousel = photos
              this.dialogPhoto = true
            },
            removePhoto(image) {
              this.$set(image, 'loading', true)
              fileService.deleteFile(image.photo).then((d) => {
                this.item.tempPhotos = this.item.tempPhotos.filter(im => im.uuid != image.uuid);
              })
            },
        }
    }
</script>

<style>
  .text-img {
    color: red;
    display: flex;
    flex-direction: column;
  }
  .text-nowrap {
    white-space: nowrap !important;
  }
  .v-btn .v-input__prepend-outer {
    margin-top: 4px;
    margin-right: 0;
  }
  .image-coord-container .v-btn {
    position: absolute;
    top: 4px;
    right: 4px;
  }
  .image-coord {
    height: 100px;
  }
  .image-coord-container {
    display: inline-block;
    position: relative;
    max-width: 200px;
    overflow: hidden;
  }
  .image-coordinator {
    height: 500px;
    max-width: 100%
  }
  .v-slide-view {
    display: inherit !important
  }
  .image-coordinator-window {
    height: 90% !important;
  }
  .image-coordinator-window .v-window, .image-coordinator-window .v-window__container, .image-coordinator-window .v-window-item, .image-coordinator-window .v-window-item div {
    height: 100% !important;
    width: 100% !important;
  }
  .image-coordinator-window img {
    max-width: 100%;
    max-height: 100%;
  }
  .image-coordinator-window .v-window__prev {
    position: fixed;
    left: 20px;
  }
  .image-coordinator-window .v-window__next {
    position: fixed;
    right: 20px;
  }
</style>